export type PaymentMethods = {
  cash: boolean;
  stripe: boolean;
  cardMachine: boolean;
};

export const defaultPaymentMethods = (): PaymentMethods => ({
  cash: true,
  stripe: false,
  cardMachine: true,
});

export enum PaymentProviderType {
  BRS_SBP = 'BRS_SBP',
  BRS_CARD = 'BRS_CARD',
  STRIPE = 'STRIPE',
}

export enum PaymentProviderStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export enum PaymentStatus {
  CANCELED = 'canceled',
  PROCESSING = 'processing',
  REQUIRES_ACTION = 'requires_action',
  REQUIRES_CAPTURE = 'requires_capture',
  REQUIRES_CONFIRMATION = 'requires_confirmation',
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
  SUCCEEDED = 'succeeded',
  EXPIRED = 'expired',
}
