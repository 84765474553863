import type { KitStore } from './kit.store';

export function getCleanKitStore(store: KitStore) {
  const result: KitStore = {};

  Object.entries(store).forEach(([key, value]) => {
    const skipConditions = [
      value === undefined,
      value === null,
      Array.isArray(value) && !value.length,
      typeof value === 'string' && !value.trim(),
    ];

    if (skipConditions.includes(true)) {
      return;
    }

    if (['amount', 'isPaidFee', 'tripadvisorReview', 'face'].includes(key)) {
      return;
    }

    result[key] = value;
  });

  return result;
}

export function isEmptyStore(store: KitStore) {
  const result: KitStore = {};

  Object.entries(store).forEach(([key, value]) => {
    const skipConditions = [
      value === undefined,
      value === null,
      Array.isArray(value) && !value.length,
      typeof value === 'string' && !value.trim(),
    ];

    if (skipConditions.includes(true)) {
      return;
    }

    result[key] = value;
  });

  return Object.keys(result).length === 0;
}
