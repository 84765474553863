<script lang="ts">
  import { onDestroy, onMount } from 'svelte';
  import { _ } from 'svelte-i18n';

  import store from '../../store/kit.store';

  export let key = 'tripadvisorReview';
  export let value: boolean;
  export let label = $_('kit.review-at-google');
  export let caption = $_('kit.review-at-google-copy');

  onDestroy(() => {
    $store[key] = false;
  });

  onMount(() => {
    if (typeof value !== 'undefined' && value !== null) {
      $store[key] = value;
    }
  });
</script>

<label class="flex items-start">
  <input type="checkbox" class="mr-2 mt-1" bind:checked={$store[key]} />

  <div class="flex-grow">
    <p>{label}</p>
    <p class="t-caption c-grey">{caption}</p>
  </div>
</label>

<style>
  input {
    color: var(--color-primary);
  }
</style>
