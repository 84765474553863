<script lang="ts">
  import { createEventDispatcher, onMount, tick } from 'svelte';
  import IMask from 'imask';

  export let amount: number;
  export let currency: string;
  export let error = '';
  export let showLoader = false;

  let form: HTMLFormElement;

  let inputCard: HTMLInputElement;
  let inputExpire: HTMLInputElement;
  let inputCVC: HTMLInputElement;

  const dispatch = createEventDispatcher();

  let card: any = {
    pan: '',
    expiry: '', // ГГММ
    cvc2: '',
    cardname: '',
  };

  $: isValidCardForm = card.pan && card.expiry && card.cvc2 && card.cardname;

  function closeModal() {
    dispatch('close');
  }

  async function onInput() {
    await tick();
    card = Object.fromEntries(new FormData(form).entries());
  }

  function fillTestCard() {
    form.querySelector<HTMLInputElement>('input[name="pan"]').value = '5100920551403998';
    form.querySelector<HTMLInputElement>('input[name="pan"]').dispatchEvent(new Event('input'));

    form.querySelector<HTMLInputElement>('input[name="expiry"]').value = '1024';
    form.querySelector<HTMLInputElement>('input[name="expiry"]').dispatchEvent(new Event('input'));

    form.querySelector<HTMLInputElement>('input[name="cvc2"]').value = '891';
    form.querySelector<HTMLInputElement>('input[name="cvc2"]').dispatchEvent(new Event('input'));

    form.querySelector<HTMLInputElement>('input[name="cardname"]').value = 'Test';
    form.querySelector<HTMLInputElement>('input[name="cardname"]').dispatchEvent(new Event('input'));
  }

  function onSubmit() {
    dispatch('pay', {
      ...card,
      pan: card.pan.replace(/\s/g, ''),
      expiry: card.expiry.split('/').reverse().join(''),
    });
  }

  onMount(() => {
    IMask(inputCard, { mask: '0000 0000 0000 0000' });
    IMask(inputExpire, { mask: '00/00' });
    IMask(inputCVC, { mask: '000' });
  });
</script>

<div class="modal" data-cy="stripe-payment-modal">
  <div class="modal-blackout" on:click={closeModal} />

  <div class="modal-dialog">
    <button type="button" aria-hidden="true" class="close" on:click|preventDefault={closeModal} />

    <div class="modal-body">
      <form class="payment-card-form" on:submit|preventDefault={onSubmit} bind:this={form}>
        <input
          type="text"
          inputmode="numeric"
          class="card-input"
          placeholder="1234 1234 1234 1234"
          name="pan"
          bind:this={inputCard}
          on:input={onInput}
          on:change={onInput}
          on:blur={onInput}
        />

        <div class="flex gap-4">
          <input
            type="text"
            inputmode="numeric"
            class="card-input"
            placeholder="MM/YY"
            name="expiry"
            bind:this={inputExpire}
            on:input={onInput}
            on:change={onInput}
            on:blur={onInput}
          />

          <input
            type="text"
            class="card-input"
            placeholder="CVC"
            name="cvc2"
            bind:this={inputCVC}
            on:input={onInput}
            on:change={onInput}
            on:blur={onInput}
          />
        </div>

        <input
          type="text"
          class="card-input"
          placeholder="Name"
          name="cardname"
          on:input={onInput}
          on:change={onInput}
          on:blur={onInput}
        />

        <div class="flex paymentAmount">
          <div>Payment amount</div>

          <div>
            <span>{(amount || 0).toFixed(2)}</span>
            {currency.toLocaleUpperCase()}
          </div>
        </div>

        {#if error}
          <div class="cardDeclined">
            <span>{error}</span>
          </div>
        {/if}

        <button id="pay-card" class="pay-card" type="submit" disabled={!isValidCardForm || showLoader}>
          {#if showLoader}
            <div id="pay-preloader">
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          {:else}
            Pay Now
          {/if}
        </button>

        {#if process.env.MODE !== 'production'}
          <div class="mt-6">
            <button type="button" on:click={fillTestCard} class="text-blue-400 underline">Use test card</button>
          </div>
        {/if}
      </form>
    </div>
  </div>
</div>

<style>
  .flex {
    display: flex;
  }

  .payment-card-form {
    min-height: 158px;
  }

  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
  }

  .modal-blackout {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(47, 47, 47, 0.7);
    z-index: 1;
  }

  .modal-dialog {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 385px;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
  }

  .close {
    background-color: #fff;
    position: absolute;
    top: -24px;
    width: 44px;
    height: 44px;
    left: calc(50% - 22px);
    border-radius: 50%;
    border: 0;
  }

  .close::before,
  .close::after {
    position: absolute;
    left: 21px;
    top: 13px;
    content: ' ';
    height: 18px;
    width: 2px;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .close::before {
    transform: rotate(45deg);
  }
  .close::after {
    transform: rotate(-45deg);
  }

  .card-input {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 16px;
    background-color: #fff;
    border-radius: 5px;
    transition: all 0.15s;
    border: 1px solid #e6e6e6;
    font: inherit;
    letter-spacing: 1px;
    box-shadow:
      0px 1px 1px rgb(0 0 0 / 3%),
      0px 3px 6px rgb(0 0 0 / 2%);
  }

  .pay-card {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 42px;
    background: rgb(5, 112, 222);
    border-radius: 5px;
    color: white;
    font-family: Arial, sans-serif;
    border: 0;
    margin-top: 10px;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .pay-card:disabled {
    opacity: 0.5;
    cursor: default;
  }

  #pay-preloader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 23px;
    width: 50px;
  }

  #pay-preloader span {
    position: absolute;
    display: block;
    bottom: 10px;
    width: 9px;
    height: 5px;
    background: rgba(0, 0, 0, 0.25);
    animation: pay-preloader 1.5s infinite ease-in-out;
  }

  #pay-preloader span:nth-child(2) {
    left: 11px;
    animation-delay: 0.2s;
  }

  #pay-preloader span:nth-child(3) {
    left: 22px;
    animation-delay: 0.4s;
  }

  #pay-preloader span:nth-child(4) {
    left: 33px;
    animation-delay: 0.6s;
  }

  #pay-preloader span:nth-child(5) {
    left: 44px;
    animation-delay: 0.8s;
  }

  @keyframes pay-preloader {
    0% {
      height: 5px;
      transform: translateY(0px);
      transform: translateY(0px);
      background: rgba(0, 0, 0, 0.25);
    }
    25% {
      height: 30px;
      transform: translateY(15px);
      transform: translateY(15px);
      background: #fff;
    }
    50% {
      height: 5px;
      transform: translateY(0px);
      transform: translateY(0px);
      background: rgba(0, 0, 0, 0.25);
    }
    100% {
      height: 5px;
      transform: translateY(0px);
      transform: translateY(0px);
      background: rgba(0, 0, 0, 0.25);
    }
  }

  .paymentAmount {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    color: #7883a3;
  }

  .paymentAmount span {
    font-weight: 700;
    color: #1e2a52;
  }

  .cardDeclined {
    display: flex;
    text-align: left;
    font-size: 14px;
    color: #c4314b;
  }
</style>
