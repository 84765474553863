import EventEmitter2 from 'eventemitter2';
import { writable, get } from 'svelte/store';
import { onDestroy } from 'svelte';
import type { CreateBrsCardPaymentDto } from '../../../server/src/payments/brs-card/dto/create-brs-card-payment.dto.ts';
import { http } from '../../http';
import BrsCard from './BrsCard.svelte';

function useBrsCard(options: { currency: string }) {
  let currency = options.currency;
  let amount = 0
  let isCheckerAlive = false;
  let win: Window | null = null;
  let modal: any;
  let modalRoot: any;

  const events = new EventEmitter2();

  const store = writable({
    isLoading: false,
    paymentId: null,

    onSuccess: events.on.bind(events, 'success'),

    createPayment,
    setCurrency,
    setAmount,
  });

  function setCurrency(c: string) {
    currency = c;
  }

  function setAmount(o: number) {
    amount = o;
  }

  async function createPayment(data: CreateBrsCardPaymentDto) {
    modalRoot = document.createElement('div');
    document.body.appendChild(modalRoot);

    modal = new BrsCard({
      target: modalRoot,
      props: {
        amount,
        currency,
      },
    });

    modal.$on('close', () => {
      modal?.$destroy();
      modalRoot?.remove();
    });

    modal.$on('pay', async (e) => {
      modal.$set({ showLoader: true });

      sendPayment({ ...data, card: e.detail }).catch((e) => {
        console.dir(e);
      });
    });
  }

  async function sendPayment(data: CreateBrsCardPaymentDto) {
    store.update((s) => ({ ...s, isLoading: true }));

    // TODO: retry a couple of times
    const { response } = await http.post('/api/payments/brs-card', data);

    store.update((s) => ({ ...s, paymentId: response.paymentId }));

    win = window.open(response.url, '_blank');
    win?.focus();

    isCheckerAlive = true;
    checkPayment();
  }

  function onSuccess() {
    onEnd();
    events.emit('success', get(store).paymentId);
    modal?.$destroy();
    modalRoot?.remove();
  }

  function onEnd() {
    store.update((s) => ({ ...s, isLoading: false }));
    win?.close();
    modal.$set({ showLoader: false });
  }

  async function checkPayment() {
    const { response } = await http
      .post('/api/payments/brs-card/check', { paymentId: get(store).paymentId })
      .catch(() => ({}));

    if (!isCheckerAlive) {
      return;
    }

    console.log(response);

    if (response?.status === 'succeeded') {
      return onSuccess();
    }

    // TODO: handle failed payments

    // if (response?.status === 'processing') {
    //   return setTimeout(() => checkPayment(), 2000);
    // }


    // let err = response?.message || 'Payment failed';
    // modal.$set({ error: err });
    // onEnd();

    setTimeout(() => checkPayment(), 2000);
  }

  onDestroy(() => {
    isCheckerAlive = false;
  });

  return store;
}

export { useBrsCard };
