<script lang="ts">
  import { createEventDispatcher, getContext } from 'svelte';

  import { sendEvent } from '../../analytics/analytics.service';
  import store from '../../store/kit.store';

  import type { KitContext, Order } from 'ui/kit/types';

  const kitContext = getContext<KitContext>('kit_context');
  const order = getContext<Order>('order');
  const dispatch = createEventDispatcher();

  export let key = 'amount';
  export let suggestAmounts: number[] = [5, 10, 15, 20];
  export let suggestPercents: number[] = [5, 10, 15, 20];
  let currency: string = kitContext.currency;

  function selectAmount(value: number) {
    $store[key] = value;
    dispatch('select', value);
    sendEvent('tips_sum', {
      tips: {
        sum: $store.amount,
      },
    });
  }
</script>

<div class="suggests">
  {#if $order?.amount}
    {#each suggestPercents as percent}
      {@const amount = Math.round(($order.amount * percent) / 100)}

      <button
        type="button"
        class="suggest"
        class:is-selected={$store[key] === amount}
        on:click={() => selectAmount(amount)}
      >
        <div class="flex items-center">
          {amount}
          <span class="ml-1 suggest-currency">{currency}</span>
        </div>
        <span class="percent t-caption">{percent}%</span>
      </button>
    {/each}
  {:else}
    {#each suggestAmounts as amount}
      <button
        type="button"
        class="suggest"
        class:is-selected={$store[key] === amount}
        on:click={() => selectAmount(amount)}
      >
        {amount}
        <span class="ml-1 suggest-currency">{currency}</span>
      </button>
    {/each}
  {/if}
</div>

<style lang="postcss">
  .suggests {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: 10px;
  }

  .suggest {
    position: relative;
    display: inline-flex;
    justify-content: center;
    height: 45px;
    border-radius: 10px;
    flex-grow: 1;
    font-size: 16px;
    border: 1px solid var(--color-primary);
    padding-top: 10px;

    .percent {
      position: absolute;
      bottom: -10px;
      border: 1px solid var(--color-primary);
      border-radius: 22px;
      background: white;
      padding: 3px 8px;
      color: var(--color-grey);
    }
  }

  .suggest.is-selected {
    background: var(--color-primary);
    color: #fff;
  }

  .suggest-currency {
    font-size: 0.75em;
    color: var(--color-grey);
  }

  .suggest.is-selected .suggest-currency {
    color: inherit;
  }
</style>
