<script lang="ts">
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';

  import { sendEvent } from '../../analytics/analytics.service';
  import store from '../../store/kit.store';

  import EtRating from 'ui/elements/et-rating/et-rating.svelte';

  export let key = 'rating';
  export let value: number;
  export let title = $_('kit.rate-service');
  export let maxRating = 5;

  function onChange(event) {
    const rating = event.detail;

    sendEvent('tips_rate', {
      tips: {
        rate: rating,
      },
    });
  }

  onMount(() => {
    if (value) {
      $store[key] = Math.min(Math.max(value, 1), maxRating);
    }
  });
</script>

<h2 class="t-h3 text-center mb-2">{title}</h2>

<EtRating bind:value={$store[key]} bind:maxRating on:change={onChange} />
