import type { PaymentIntent, StripeCardNumberElement } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import _ from 'lodash';

import { stripeLogger } from '../../lib/stripe-logger';

const loadStripeMemo = _.memoize((stripeId?: string) => {
  return loadStripe(`${process.env.STRIPE_PUBLISHABLE}`, {
    stripeAccount: stripeId || undefined,
  });
});

export { loadStripeMemo as loadStripe };

export async function confirmCardPayment(
  stripeId: string,
  createPaymentIntent: () => Promise<{ clientSecret: string; statusCode?: number; message?: string }>,
  cardNumberElement: StripeCardNumberElement,
) {
  const stripe = await loadStripeMemo(stripeId);
  let clientSecret: string;
  let paymentIntent: PaymentIntent;

  try {
    const data = await createPaymentIntent();

    if (data.statusCode >= 400) {
      throw new Error(data.message);
    }

    clientSecret = data.clientSecret;

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardNumberElement,
      },
    });

    if (result.error) {
      stripeLogger.error(result.error);
      throw result.error;
    }
    paymentIntent = result.paymentIntent;
  } catch (error) {
    stripeLogger.error(error);
    throw error;
  }

  return paymentIntent;
}
