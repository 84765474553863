<script lang="ts">
  import type { PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js';
  import { isUndefined, omitBy } from 'lodash-es';
  import { PaymentProviderType } from 'shared/payment-methods.js';
  import { createEventDispatcher } from 'svelte';
  import { useBrsCard } from 'ui/payment/brs-card/brs-card';
  import { useSBP } from 'ui/payment/sbp/sbp';
  import { StripePayment } from 'ui/payment/stripe/StripePayment';
  import type { CreateBrsCardPaymentDto } from '../../server/src/payments/brs-card/dto/create-brs-card-payment.dto';
  import type { PaymentProvider } from '../../server/src/payments/entities/payment-provider.entity';
  import type { CreateSBPPaymentDto } from '../../server/src/payments/sbp/dto/create-sbp-payment.dto';

  export let unitId: string;
  export let purpose: string;
  export let currency: string;
  export let reviewId: string = undefined;
  export let orderReviewId: string = undefined;
  export let externalOrderIds: string[] = undefined;
  export let orderIds: string[] = undefined;
  export let employees: Array<{ id: string; amount: number }> = undefined;
  export let tipsAmount: number = undefined;
  export let amountPaidFee: number = undefined;
  export let stripeId: string = undefined;

  // TODO: legacy, calculate order amount on the backend, remove later
  export let amount: number = undefined;

  export let paymentProviders: PaymentProvider[] = [];
  export let beforePayment: (data: { provider: string }) => Promise<any>;

  export let isAvailable = true;
  export let buttonText: string = undefined;
  export let stripeCardText: string = undefined;
  export let brsCardText: string = undefined;
  export let sbpCardText: string = undefined;
  // TODO: any better way ?
  export let hideApple = false;
  export let hideStripeCard = false;

  const dispatch = createEventDispatcher();

  let stripePayment: StripePayment;

  $: stripePayment && stripePayment.setPaymentDescription(purpose);
  $: stripePayment && stripePayment.setAmount((amount || 0) + (tipsAmount || 0));
  $: onChangePaymentProviders(paymentProviders);

  function onChangePaymentProviders(paymentProviders: PaymentProvider[]) {
    if (paymentProviders.find((p) => p.type === PaymentProviderType.STRIPE)) {
      if (!stripePayment) {
        stripePayment = new StripePayment({ currency, stripeId });
        stripePayment.on('success', (e) => onSuccess(PaymentProviderType.STRIPE, e.transaction_id));
        stripePayment.on('modalClose', dispatch.bind(null, 'cancel'));
        stripePayment.on('paymentmethod', async (e: PaymentRequestPaymentMethodEvent) => {
          if (beforePayment) {
            await beforePayment({ provider: PaymentProviderType.STRIPE });
          }
          setupStripeMetadata();
          void stripePayment.getPaymentIntent(e);
        });
      }
      stripePayment.setPaymentDescription(purpose);
      stripePayment.setAmount((amount || 0) + (tipsAmount || 0));
    }
  }

  const brsCard = useBrsCard({ currency });
  $brsCard.onSuccess((e) => onSuccess(PaymentProviderType.BRS_CARD, e));

  const sbp = useSBP();
  $sbp.onSuccess((e) => onSuccess(PaymentProviderType.BRS_SBP, e));

  async function onPayByCard() {
    if (beforePayment) {
      await beforePayment({ provider: PaymentProviderType.STRIPE });
    }
    setupStripeMetadata();
    stripePayment.mount();
  }

  async function createBrsCardPayment() {
    if (beforePayment) {
      await beforePayment({ provider: PaymentProviderType.BRS_CARD });
    }

    $brsCard.setCurrency(currency);
    $brsCard.setAmount((amount || 0) + (tipsAmount || 0));

    const data: CreateBrsCardPaymentDto = {
      unitId,
      purpose,

      orderIds: orderIds?.length ? orderIds : undefined,
      externalOrderIds: externalOrderIds?.length ? externalOrderIds : undefined,

      tipsAmount: tipsAmount || undefined,
      employees: employees?.length ? employees : undefined,

      reviewId: reviewId || undefined,
      orderReviewId: orderReviewId || undefined,

      // @ts-expect-error skip
      card: {},
    };

    $brsCard.createPayment(omitBy(data, isUndefined) as CreateBrsCardPaymentDto);
  }

  async function createSBPPayment() {
    if (beforePayment) {
      await beforePayment({ provider: PaymentProviderType.BRS_SBP });
    }

    const data: CreateSBPPaymentDto = {
      unitId,
      purpose,

      orderIds: orderIds?.length ? orderIds : undefined,
      externalOrderIds: externalOrderIds?.length ? externalOrderIds : undefined,

      tipsAmount: tipsAmount || undefined,
      employees: employees?.length ? employees : undefined,

      reviewId: reviewId || undefined,
      orderReviewId: orderReviewId || undefined,
    };

    $sbp.createPayment(omitBy(data, isUndefined) as CreateSBPPaymentDto);
  }

  async function setupStripeMetadata() {
    stripePayment.setPaymentDescription(purpose);

    stripePayment.setReceiver(
      omitBy(
        {
          unitId,
          employeeIds: employees?.map((e) => e.id).join(',') || undefined,
        },
        isUndefined,
      ) as any,
    );

    let metadata = {
      reviewId: reviewId || undefined,
      orderReviewId: orderReviewId || undefined,
      menuOrderId: orderIds?.join(',') || undefined,
      amountPaidFee: amountPaidFee || undefined,
      // iikoOrderId: order.id
    };

    if (employees?.length) {
      employees.forEach((e) => {
        metadata[e.id] = e.amount;
      });
    }

    stripePayment.setMetadata(omitBy(metadata, isUndefined));
  }

  function onSuccess(provider, paymentId) {
    dispatch('success', { provider, paymentId });
  }
</script>

<div class="payment-buttons-wrap is-available flex gap-4" class:is-available={isAvailable}>
  <!-- {#if currency !== AvailableCurrency.RUB} -->
  {#if paymentProviders.find((p) => p.type === PaymentProviderType.STRIPE)}
    <div class="payment-vendor" bind:this={stripePayment.container} class:hidden={hideApple} />

    <button
      type="button"
      class="btn is-primary grow"
      on:click|preventDefault={onPayByCard}
      class:hidden={hideStripeCard}
      data-cy="kit-leavetip-button"
    >
      <!-- <IconCoin class="mr-2" /> -->
      {buttonText || stripeCardText || 'Pay'}
    </button>
  {/if}

  {#if paymentProviders.find((p) => p.type === PaymentProviderType.BRS_CARD)}
    <button
      type="button"
      class="btn is-primary grow"
      on:click={createBrsCardPayment}
      class:is-loading={$brsCard.isLoading}
    >
      {buttonText || brsCardText || 'Оплатить Картой'}
    </button>
  {/if}

  {#if paymentProviders.find((p) => p.type === PaymentProviderType.BRS_SBP)}
    <button type="button" class="btn is-primary grow" on:click={createSBPPayment} class:is-loading={$sbp.isLoading}>
      {buttonText || sbpCardText || 'Оплатить по СБП'}
    </button>
  {/if}

  <slot></slot>
</div>

<style>
  .payment-vendor {
    flex-grow: 1;
  }

  .payment-vendor :global(iframe) {
    border-radius: 10px;
    overflow: hidden;
  }
  .payment-vendor:empty {
    display: none;
  }
</style>
