import type {
  PaymentIntent,
  PaymentRequest,
  PaymentRequestPaymentMethodEvent,
  StripeCardNumberElement,
  StripePaymentRequestButtonElement,
} from '@stripe/stripe-js';
import { EventEmitter2 } from 'eventemitter2';

import { stripeLogger } from '../../lib/stripe-logger';

import StripePaymentModal from './StripePaymentModal.vue';
import { confirmCardPayment, loadStripe } from './lib';
import { App, ComponentPublicInstance, createApp } from 'vue';

type StripePaymentParams = {
  currency: string;
  stripeId: string | null;
};

type Receiver = {
  unitId: string;
  employeeId?: string;
  employeeIds?: string;
  iikoOrderId?: string;
};

// ?
type Metadata = {
  [key: string]: any;
};

export class StripePayment extends EventEmitter2 {
  private currency: string;
  private stripeId: string;
  private modalRoot: HTMLDivElement;
  private modal: ComponentPublicInstance;
  private modalApp: App;

  private amount: number;

  private receiver: Receiver;
  private paymentDescription: string;
  private metadata: Metadata = {};

  private paymentRequest: PaymentRequest;
  private prButton: StripePaymentRequestButtonElement;
  public canMakeWalletPay = undefined;
  public container: HTMLElement;

  constructor(params: StripePaymentParams) {
    super();

    this.currency = params.currency.toLowerCase();
    if (params.stripeId) {
      this.stripeId = params.stripeId;
    }
  }

  public setReceiver(receiver: Receiver) {
    this.receiver = receiver;
  }

  public setPaymentDescription(value: string) {
    this.paymentDescription = value;
  }

  public setMetadata(data: Metadata) {
    this.metadata = data;
  }

  public async setAmount(value: number) {
    this.amount = value;
    await this.onUpdateAmount();
  }

  public mount() {
    this.mountModal();
  }

  public async pay(cardNumberElement: StripeCardNumberElement) {
    this.modal.$.props.showLoader = true;

    const paymentIntent = await confirmCardPayment(this.stripeId, this.createPaymentIntent, cardNumberElement)
      .catch((err) => {
        this.modal.$.props.stripeError = err;
        throw err;
      })
      .finally(() => {
        this.modal.$.props.showLoader = false;
      });

    //console.log(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
    this.unmountModal();

    this.emit('success', {
      transaction_id: paymentIntent.id,
    });
  }

  private mountModal() {
    this.modalRoot = document.createElement('div');

    document.body.appendChild(this.modalRoot);

    this.modalApp = createApp(StripePaymentModal, {
      amount: this.amount,
      currency: this.currency,
      stripeId: this.stripeId,

      onClose: () => {
        this.emit('modalClose');
        this.unmountModal();
      },

      onPay: (detail: StripeCardNumberElement) => {
        this.pay(detail);
      },
    });

    this.modal = this.modalApp.mount(this.modalRoot);

    this.emit('mounted');
  }

  private unmountModal() {
    this.modalApp?.unmount();
    this.modalRoot?.remove();
  }

  private createPaymentIntent = () => {
    const body = {
      amount: Math.round(this.amount * 100),
      receiver: this.receiver,
      currency: this.currency,
      description: this.paymentDescription,
      metadata: this.metadata,
      paymentMethodType: 'card',
    };

    if (process.env.MODE !== 'production') {
      console.log(body);
    }

    return fetch('/api/payments/stripe/create-payment-intent', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then<{ clientSecret: string }>((r) => r.json());
  };

  async getPaymentIntent(event: PaymentRequestPaymentMethodEvent) {
    let clientSecret: string;
    try {
      const data = await this.createPaymentIntent();
      clientSecret = data.clientSecret;
    } catch (error) {
      stripeLogger.error(error);
      event.complete('fail');
      return;
    }
    // console.log('Client secret returned.');

    const stripe = await loadStripe(this.stripeId);

    let paymentIntent: PaymentIntent;
    try {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      const data = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: event.paymentMethod.id,
        },
        {
          handleActions: false,
        },
      );
      if (data.error) {
        stripeLogger.error(data.error);

        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        event.complete('fail');
        return;
      }
      paymentIntent = data.paymentIntent;
    } catch (error) {
      stripeLogger.error(error);

      event.complete('fail');
      return;
    }

    // console.log(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);

    // Report to the browser that the confirmation was successful, prompting
    // it to close the browser payment method collection interface.
    event.complete('success');

    // Check if the PaymentIntent requires any actions and if so let Stripe.js
    // handle the flow. If using an API version older than "2019-02-11" instead
    // instead check for: `paymentIntent.status === "requires_source_action"`.
    if (paymentIntent.status === 'requires_action') {
      // Let Stripe.js handle the rest of the payment flow.
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret);
      // console.log(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
      if (error) {
        // The payment failed -- ask the customer for a new payment method.
        stripeLogger.error(error);
        return;
      }
    }

    this.emit('success', {
      transaction_id: paymentIntent.id,
    });
  }

  async onUpdateAmount() {
    if (this.amount === undefined || this.amount === null) {
      return;
    }

    const stripe = await loadStripe(this.stripeId);

    const total = {
      label: this.paymentDescription,
      amount: Math.round(this.amount * 100),
    };

    if (!this.paymentRequest) {
      this.paymentRequest = stripe.paymentRequest({
        country: 'AE',
        currency: this.currency,
        total,
      });

      this.paymentRequest.on('paymentmethod', (e) => {
        this.emit('paymentmethod', e);
      });
    } else {
      this.paymentRequest.update({ total });
    }

    this.canMakeWalletPay = await this.paymentRequest.canMakePayment();

    if (this.canMakeWalletPay) {
      const elements = stripe.elements();
      if (!this.prButton) {
        this.prButton = elements.create('paymentRequestButton', {
          paymentRequest: this.paymentRequest,
        });
        this.prButton.mount(this.container);
      }
    }
  }

  public destroy() {
    this.prButton?.unmount();
    this.unmountModal();
  }
}
