import { writable } from 'svelte/store';

import type { ReviewImage } from '../elements/UploadImage/types';

type ReviewChoice = {
  question: string;
  type: 'choice' | 'star';
  value: number | string;
};

export type KitStore = {
  amount?: number | null;
  rating?: number | null;
  comment?: string;
  details?: string[];
  phone?: string | null;
  name?: string | null;
  email?: string | null;
  images?: ReviewImage[];
  choices?: ReviewChoice[];
  face?: number;

  // meta
  externalOrderId?: string;
  orderId?: string;
  isPaidFee?: boolean;
  amountPaidFee?: number;
  reviewId?: string;
  tripadvisorReview?: boolean;
};

export const initData: KitStore = {
  amount: null,
  rating: null,
  comment: null,
  details: null,
  phone: null,
  name: null,
  email: null,
  images: null,
  choices: null,
  face: null,

  // meta
  externalOrderId: null,
  orderId: null,
  isPaidFee: null,
  amountPaidFee: null,
  reviewId: null,
  tripadvisorReview: null,
}

const store = writable({...initData});

export default store;

export function resetKit() {
  store.set({...initData});
}
