<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  import IconFilledStar from 'ui/icons/IconFilledStar.svg';
  import IconStar from 'ui/icons/IconStar.svg';

  export let value: number;
  export let maxRating = 5;

  const dispatch = createEventDispatcher();

  function setRating(rating: number) {
    value = rating;
    dispatch('change', rating);
  }
</script>

<div class="rating-wrap">
  {#each Array(maxRating) as _, rating}
    <button type="button" class="rating" on:click={() => setRating(rating + 1)}>
      {#if value >= rating + 1}
        <IconFilledStar />
      {:else}
        <IconStar />
      {/if}
    </button>
  {/each}
</div>

<style>
  .rating-wrap {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }

  .rating {
    padding: 8px 0;
    color: var(--color-primary);

    --icon-size: 30px;
  }
</style>
