/**
 * Svelte
 */
import { writable } from 'svelte/store';

/**
 * Interface
 */

export interface ITipAnalytics {
  event: string | boolean;
  tips: {
    sum?: number;
    currency?: 'AED';
    rate?: number;
    // atmosphere?: boolean;
    // 'beauty-service'?: boolean;
    // food?: boolean;
    // hookah?: boolean;
    // service?: boolean;
    // team?: boolean;
    details?: string[];
    transaction_id?: string;
  };
  unit_id?: string;
  type?: {
    employee: boolean;
    order: boolean;
  };
}

/**
 * Store
 */
export const analytics = writable<ITipAnalytics>({
  event: false,
  tips: {
    sum: 0,
    currency: 'AED',
    rate: 0,
  },
});
