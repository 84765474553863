import EventEmitter2 from 'eventemitter2';
import { writable, get } from 'svelte/store';
import { onDestroy } from 'svelte';
import type { CreateSBPPaymentDto } from '../../../server/src/payments/sbp/dto/create-sbp-payment.dto.ts';
import { http } from '../../http';

function useSBP() {
  let isCheckerAlive = false;
  let win: Window | null = null;

  const events = new EventEmitter2();

  const store = writable({
    isLoading: false,
    paymentId: null,

    onSuccess: events.on.bind(events, 'success'),

    createPayment,
  });

  async function createPayment(data: CreateSBPPaymentDto) {
    store.update((s) => ({ ...s, isLoading: true }));

    const { response } = await http.post('/api/payments/sbp', data);

    store.update((s) => ({ ...s, paymentId: response.paymentId }));

    win = window.open(response.url, '_blank');
    win?.focus();

    isCheckerAlive = true;
    checkPayment();
  }

  function onSuccess() {
    onEnd();
    events.emit('success', get(store).paymentId);
  }

  function onError() {
    onEnd();
    events.emit('error', get(store).paymentId);
  }

  function onEnd() {
    store.update((s) => ({ ...s, isLoading: false }));
    win?.close();
  }

  async function checkPayment() {
    const { response } = await http
      .post('/api/payments/sbp/check', { paymentId: get(store).paymentId })
      .catch(() => ({}));

    if (!isCheckerAlive) {
      return;
    }

    console.log(response);

    if (response?.status === 'processing') {
      return setTimeout(() => checkPayment(), 2000);
    }

    if (response?.status === 'succeeded') {
      return onSuccess();
    }

    if (response?.status === 'canceled') {
      return onError();
    }

    // alert(response?.message);
    onEnd();
  }

  onDestroy(() => {
    isCheckerAlive = false;
  });

  return store;
}

export { useSBP };
