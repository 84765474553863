<script lang="ts">
  import { getContext, onMount } from 'svelte';
  import { _ } from 'svelte-i18n';

  import { sendEvent } from '../../analytics/analytics.service';
  import store from '../../store/kit.store';

  import IconCloseCircle from 'ui/icons/IconCloseCircle.svg';
  import type { KitContext } from 'ui/kit';

  const kitContext = getContext<KitContext>('kit_context');

  export let key = 'amount';
  export let value: number;
  export let minAmount = 2;
  export let placeholder = '';

  let currency = kitContext.currency;

  let input: HTMLInputElement;
  let isDirty = false;
  let showMinTip = false;

  function clear() {
    $store[key] = null;
    input.focus();

    onFocus();
  }

  function onFocus() {
    isDirty = true;
  }

  function onBlur() {
    showMinTip = $store[key] && $store[key] < minAmount;
    isDirty = true;
    sendEvent('tips_sum', { tips: { sum: $store[key] } });
  }

  onMount(() => {
    if (!placeholder) {
      placeholder = $_('kit.amount');
    }

    if (value) {
      $store[key] = value;
    }
  });
</script>

<div class="amount-input-wrap">
  {#if $store[key]}
    <div class="amount-pseudo">
      <span class="amount-pseudo-text">{$store[key]}</span>
      <span class="amount-pseudo-currency">{currency}</span>
    </div>
  {/if}

  <input
    type="number"
    min="1"
    step="0.01"
    inputmode="decimal"
    {placeholder}
    class="amount-input"
    name="amount"
    bind:value={$store[key]}
    bind:this={input}
    on:focus={onFocus}
    on:blur={onBlur}
    data-cy="kit-amount-element"
  />

  {#if $store[key] !== null}
    <button type="button" class="amount-clear" on:click={clear}>
      <IconCloseCircle />
    </button>
  {/if}
</div>

{#if showMinTip}
  <div class="feedback is-invalid">{$_('kit.min-amount', { values: { val: `${minAmount} ${currency}` } })}</div>
{/if}

<style>
  .amount-input-wrap {
    border-bottom: 1px solid;
    border-image: var(--base-gradient) 1;
    font-weight: 800;
    font-size: 24px;
    position: relative;
  }

  .amount-input,
  .amount-pseudo {
    padding: 0;
    border: 0;
    background: transparent;
    font: inherit;
    text-align: center;
    width: 100%;
    height: 50px;
    line-height: 50px;
  }

  .amount-pseudo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
  }

  .amount-pseudo-currency {
    position: absolute;
    left: calc(100% + 5px);
    top: 0;
    font-size: 0.7em;
    color: var(--color-grey);
  }

  .amount-pseudo-text {
    opacity: 0;
  }

  .amount-input {
    appearance: textfield;
    position: relative;
    z-index: 1;
    outline: none;
    box-shadow: none;
  }

  .amount-input::placeholder {
    font-weight: normal;
    font-size: 0.8em;
  }

  .amount-input::-webkit-outer-spin-button,
  .amount-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .amount-clear {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    line-height: 1;
    font-size: 1.1em;
    padding: 0.1em;
    color: var(--color-grey);
    z-index: 1;
  }
</style>
