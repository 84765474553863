<script lang="ts">
  import { getContext } from 'svelte';
  import type { Employee, Unit } from 'ui/kit/types';
  import { _ } from 'svelte-i18n';

  const unit = getContext<Unit>('unit');
  const employee = getContext<Employee>('employee');

  $: style = getStyles(unit);

  function getStyles() {
    let res = {};

    if (unit.bg) {
      res['background-image'] = `url('${unit.bg.url}')`;
    } else if (unit.menuSettings?.bgColor) {
      res['--header-bg'] = unit.menuSettings?.bgColor;
      if (['#000', '#000000'].includes(unit.menuSettings?.bgColor)) {
        res['--header-color'] = '#fff';
      }
    }

    if (!unit.bg && !unit.menuSettings?.bgColor) {
      res['--header-color'] = '#000';
    }

    return Object.entries(res)
      .map(([k, v]) => `${k}: ${v};`)
      .join(' ');
  }
</script>

<header class="header" {style}>
  <div class="header-wrap">
    {#if !employee}
      <div class="header-logo">
        {#if unit.logo || unit.logoSite}
          <img src={unit.logo?.url || unit.logoSite?.url} width="175" height="175" alt={unit.name} />
        {:else}
          {unit.name}
        {/if}
      </div>
    {:else}
      <div class="employee">
        {#if employee.avatar}
          <div class="employee-avatar-container">
            <img src={employee.avatar.url} alt={employee.firstName} class="employee-avatar" />
            {#if unit.logo}
              <div class="unit-image-employee" style="background-image: url('{unit.logo.url}')" />
            {/if}
          </div>
        {:else if unit.logo}
          <div class="employee-avatar-container">
            <img src={unit.logo.url} alt={unit.name} class="employee-avatar" />
          </div>
        {/if}

        <div class="t-h3 mt-3">{employee.firstName || ''}</div>

        {#if employee.position}
          <div class="employee-position">{employee.position} {$_('kit.employee-at-unit')} {unit.name}</div>
        {/if}
      </div>
    {/if}
  </div>
</header>

<style>
  .header {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: var(--header-bg);
    position: relative;
    height: 270px;
  }

  .header-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: var(--header-color, var(--color-white));
  }

  .header-logo {
    font-size: 24px;
    letter-spacing: 0.1em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
    text-align: center;
  }

  .header-logo img {
    display: block;
    max-width: 175px;
    max-height: 175px;
  }

  .employee {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .employee-avatar {
    display: block;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
    border-radius: inherit;
  }

  .employee-avatar-container {
    display: block;
    margin: 0 auto;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    padding: 6px;
    position: relative;
  }

  .unit-image-employee {
    width: 44px;
    height: 44px;
    border: 2px solid #ffffff;
    background: var(--bg-color-tip-personal-rest-logo);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    right: -11px;
    bottom: 0;
    border-radius: 50%;
  }
</style>
