import merge from 'lodash/merge';
import { get } from 'svelte/store';

import type { ITipAnalytics } from './analytics.store';
import { analytics } from './analytics.store';

// move to global.d.ts?
declare global {
  interface Window {
    dataLayer?: any[];
  }
}

type TGtmName =
  | 'view_tips'
  | 'view_tips_multiple'
  | 'tips_sum'
  | 'tips_response'
  | 'tips_option'
  | 'tips_rate'
  | 'tips_show_pay_form'
  | 'tips_pay'
  | 'tips_review'
  | 'tips_purchase';

export function sendEvent(name: TGtmName, additionalData: Partial<ITipAnalytics> = {}) {
  const data = get(analytics);
  const newData = merge(data, additionalData);
  analytics.set(newData);
  pushDataLayer({
    ...newData,
    event: name,
  });
}

export function pushDataLayer(data: any) {
  if (!window.dataLayer) {
    console.warn('[analytics.service] window.dataLayer is not defined');
    return;
  }

  window.dataLayer.push(data);
}

export function updateDataLayer(analytics: ITipAnalytics) {
  if (!window.dataLayer) {
    console.warn('[analytics.service] window.dataLayer is not defined');
    return;
  }

  if (analytics.event) {
    window.dataLayer.push({
      ...analytics,
    });
  }
}
