<script lang="ts" setup>
import type { StripeCardCvcElement, StripeCardExpiryElement, StripeCardNumberElement } from '@stripe/stripe-js';
import { loadStripe } from './lib';
import { onMounted, computed, onUnmounted, reactive, ref } from 'vue';

const props = defineProps<{
  showLoader: boolean;
  amount: number;
  currency: string;
  stripeId: string;
  stripeError: any;

  onClose: () => void;
  onPay: (e: StripeCardNumberElement) => void;
}>();

let readyCardElements = reactive({});
let cardNumberElement = ref<StripeCardNumberElement>();
let cardExpiryElement = ref<StripeCardExpiryElement>();
let cardCvcElement = ref<StripeCardCvcElement>();

const formLoaded = computed(() => {
  return Object.keys(readyCardElements).length < 3;
});

const isValidCardForm = computed(() => {
  for (const element in readyCardElements) {
    if (!readyCardElements[element]['complete']) {
      return false;
    }
  }
  return true;
});

function closeModal() {
  props.onClose();
}

function pay() {
  props.onPay(cardNumberElement.value!);
}

onMounted(async () => {
  const stripe = await loadStripe(props.stripeId);
  const elements = stripe.elements();

  cardNumberElement.value = elements.create('cardNumber');
  cardExpiryElement.value = elements.create('cardExpiry');
  cardCvcElement.value = elements.create('cardCvc');

  cardNumberElement.value.mount('#card-number');
  cardExpiryElement.value.mount('#card-expiry');
  cardCvcElement.value.mount('#card-cvc');

  cardNumberElement.value.on('ready', (event) => {
    readyCardElements[event.elementType] = { ...event };
  });

  cardExpiryElement.value.on('ready', (event) => {
    readyCardElements[event.elementType] = { ...event };
  });

  cardCvcElement.value.on('ready', (event) => {
    readyCardElements[event.elementType] = { ...event };
  });

  cardNumberElement.value.on('change', (event) => {
    readyCardElements[event.elementType] = { ...event };
  });

  cardExpiryElement.value.on('change', (event) => {
    readyCardElements[event.elementType] = { ...event };
  });

  cardCvcElement.value.on('change', (event) => {
    readyCardElements[event.elementType] = { ...event };
  });
});

onUnmounted(() => {
  cardNumberElement.value?.unmount();
  cardExpiryElement.value?.unmount();
  cardCvcElement.value?.unmount();
});
</script>

<template>
  <div class="modal" data-cy="stripe-payment-modal">
    <div class="modal-blackout" @click="closeModal"></div>
    <div class="modal-dialog">
      <button type="button" aria-hidden="true" class="close" @click.prevent="closeModal"></button>

      <div class="modal-body">
        <div class="payment-card-form" :class="{ hidden: formLoaded }">
          <div id="card-number"></div>

          <div class="flex">
            <div id="card-expiry"></div>
            <div id="card-cvc"></div>
          </div>

          <div class="flex paymentAmount">
            <div>Payment amount</div>

            <div>
              <span>{{ props.amount.toFixed(2) }}</span>
              {{ props.currency.toLocaleUpperCase() }}
            </div>
          </div>

          <div class="cardDeclined" v-if="stripeError">
            <span>{{stripeError.message}}</span>
          </div>

          <button id="pay-card" class="pay-card" type="submit" @click.prevent="pay" :disabled="!isValidCardForm || showLoader">
            <div id="pay-preloader" v-if="showLoader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <span v-else>Pay Now</span>
          </button>
        </div>

        <div class="modal-loading" v-if="formLoaded">
          <div class="spinner"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.flex {
  display: flex;
}

.payment-card-form {
  min-height: 158px;
}

.payment-card-form.hidden {
  display: none;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.modal-blackout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(47, 47, 47, 0.7);
  z-index: 1;
}

.modal-dialog {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 385px;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
}

.modal-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 158px;
}

.close {
  background-color: #fff;
  position: absolute;
  top: -24px;
  width: 44px;
  height: 44px;
  left: calc(50% - 22px);
  border-radius: 50%;
  border: 0;
}

.close::before,
.close::after {
  position: absolute;
  left: 21px;
  top: 13px;
  content: ' ';
  height: 18px;
  width: 2px;
  background-color: rgba(0, 0, 0, 0.5);
}

.close::before {
  transform: rotate(45deg);
}

.close::after {
  transform: rotate(-45deg);
}

#card-number,
#card-expiry,
#card-cvc {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 5px;
  transition: all 0.15s;
  border: 1px solid #e6e6e6;
  box-shadow:
    0px 1px 1px rgb(0 0 0 / 3%),
    0px 3px 6px rgb(0 0 0 / 2%);
}

#card-number {
  width: auto;
}

#card-expiry {
  margin-inline-end: 10px;
}

.pay-card {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 42px;
  background: rgb(5, 112, 222);
  border-radius: 5px;
  color: white;
  font-family: Arial, sans-serif;
  border: 0;
  margin-top: 10px;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.pay-card:disabled {
  opacity: 0.5;
  cursor: default;
}

#pay-preloader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 23px;
  width: 50px;
}

#pay-preloader span {
  position: absolute;
  display: block;
  bottom: 10px;
  width: 9px;
  height: 5px;
  background: rgba(0, 0, 0, 0.25);
  animation: pay-preloader 1.5s infinite ease-in-out;
}

#pay-preloader span:nth-child(2) {
  left: 11px;
  animation-delay: 0.2s;
}

#pay-preloader span:nth-child(3) {
  left: 22px;
  animation-delay: 0.4s;
}

#pay-preloader span:nth-child(4) {
  left: 33px;
  animation-delay: 0.6s;
}

#pay-preloader span:nth-child(5) {
  left: 44px;
  animation-delay: 0.8s;
}

@keyframes pay-preloader {
  0% {
    height: 5px;
    transform: translateY(0px);
    transform: translateY(0px);
    background: rgba(0, 0, 0, 0.25);
  }

  25% {
    height: 30px;
    transform: translateY(15px);
    transform: translateY(15px);
    background: #fff;
  }

  50% {
    height: 5px;
    transform: translateY(0px);
    transform: translateY(0px);
    background: rgba(0, 0, 0, 0.25);
  }

  100% {
    height: 5px;
    transform: translateY(0px);
    transform: translateY(0px);
    background: rgba(0, 0, 0, 0.25);
  }
}

.spinner {
  width: 40px;
  height: 40px;

  border: 4px solid #f3f3f3;
  border-top: 5px solid #0570de;
  border-radius: 100%;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.paymentAmount {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  color: #7883a3;
}

.paymentAmount span {
  font-weight: 700;
  color: #1e2a52;
}

.cardDeclined {
  display: flex;
  text-align: left;
  font-size: 14px;
  color: #c4314b;
}
</style>
